import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";
import {
  Column,
  CurvedSectionDivider,
  CurvedSectionDividerGlow,
  FormattedText,
  Gain,
  Grid,
  Gutter,
  HelmetSeo,
  Layout,
  Line,
  MaxWidth,
  PageMaxWidth,
  Section,
  SectionQuotes,
  SectionUseCases,
  SimpleHero,
  Text,
} from "../components";
import { fixedImage, fluidImage } from "../prismic-tools";
import { unwrapFirst } from "../util";
import Img from "gatsby-image";
import { Dimen } from "../constants";

type Props = {
  data: GatsbyTypes.WhyShipamaxPageQuery;
};

export const query = graphql`
  query WhyShipamaxPage {
    allPrismicWhyShipamaxPage {
      edges {
        node {
          data {
            hero_title
            hero_paragraph {
              raw
            }
            hero_contact_button_label
            hero_calculator_button_label

            intro_photo {
              alt
              dimensions {
                width
                height
              }
              url
            }
            intro_title
            intro_content {
              raw
            }

            steps_title
            steps {
              step_icon {
                alt
                dimensions {
                  width
                  height
                }
                url
              }
              step_title
              step_description
            }

            gains {
              gain_photo {
                alt
                dimensions {
                  width
                  height
                }
                url
              }
              gain_pre_title
              gain_title
              gain_content {
                raw
              }
            }

            use_cases_title

            seo_title
            seo_description
            share_image {
              alt
              dimensions {
                width
                height
              }
              url
            }
          }
        }
      }
    }
  }
`;

export default function WhyShipamaxPage({ data }: Props) {
  const page = unwrapFirst(data.allPrismicWhyShipamaxPage).data;

  if (!page) throw new Error("page missing");

  return (
    <Layout showDemoBanner>
      <HelmetSeo {...page} />

      <Section>
        <PageMaxWidth>
          <Gutter size={3} />
          <SimpleHero
            title={page.hero_title}
            description={page.hero_paragraph}
            contactButtonLabel={page.hero_contact_button_label}
            calculatorButtonLabel={page.hero_calculator_button_label}
          />
          <Gutter size={4} />
        </PageMaxWidth>
      </Section>

      <CurvedSectionDividerGlow
        position="top"
        glowColor="orange"
        backColor="light1"
      />
      <Section background="dark" tint="light">
        <PageMaxWidth>
          <Grid>
            <Column size={5}>
              <MaxWidth width={440}>
                <Img fluid={fluidImage(page.intro_photo, 440)} />
              </MaxWidth>
            </Column>
            <Column size={1} />
            <Column size={6}>
              <Text as="h2" typeface="h3" bold>
                {page.intro_title}
              </Text>
              <Gutter size={1.25} />
              <FormattedText>
                <RichText render={page.intro_content?.raw} />
              </FormattedText>
            </Column>
          </Grid>
          <Gutter size={4} />
          <Line color="orange1" />
          <Gutter size={4} />
          <Text as="h2" typeface="h3" bold>
            {page.steps_title}
          </Text>
          <Gutter size={2.5} />
          <Grid>
            {page.steps?.map((step, i) => {
              if (!step) return null;
              return (
                <Column key={i} size={3}>
                  <StyledStep>
                    <div className="area-top">
                      <Text typeface="h5" bold>
                        Step {i + 1}
                      </Text>

                      {step.step_icon && (
                        <Img
                          fixed={fixedImage(step.step_icon, { w: 36, h: 36 })}
                        />
                      )}
                    </div>

                    <div>
                      <Text as="h3" typeface="h5" bold>
                        {step.step_title}
                      </Text>
                      <Gutter size={0.5} />
                      <Text typeface="caption" as="p">
                        {step.step_description}
                      </Text>
                    </div>
                    {i < 3 && (
                      <StyledStepSymbol>
                        <Text typeface="h3" color="green1">
                          {i === 2 ? "=" : "+"}
                        </Text>
                      </StyledStepSymbol>
                    )}
                  </StyledStep>
                </Column>
              );
            })}
          </Grid>
          <Gutter size={2} />
        </PageMaxWidth>
      </Section>
      <CurvedSectionDivider
        position="bottom"
        frontColor="newDark2"
        backColor="light1"
      />

      <Section>
        <PageMaxWidth>
          <Gutter size={6} md={2.5} />
          {page.gains?.map(
            (gain, i) =>
              gain && (
                <>
                  <Gain
                    reverseColumns={i % 2 === 0}
                    preTitle={gain.gain_pre_title}
                    title={gain.gain_title}
                    content={gain.gain_content}
                    image={gain.gain_photo}
                  />
                  <Gutter size={6} md={2.5} />
                </>
              )
          )}
        </PageMaxWidth>
      </Section>

      <CurvedSectionDivider
        position="top"
        frontColor="newDark2"
        backColor="light1"
      />
      <SectionQuotes />
      <CurvedSectionDivider
        position="bottom"
        frontColor="newDark2"
        backColor="light1"
      />

      <Gutter size={6} />
      <SectionUseCases title={page.use_cases_title} />
      <Gutter size={6} />
    </Layout>
  );
}

const StyledStep = styled.div`
  border: 1px rgba(255, 255, 255, 0.1) solid;
  border-radius: 8px;
  padding: ${Dimen.gutterWidth}px;
  min-height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .area-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    min-height: auto;
  }
`;

const StyledStepSymbol = styled.div`
  position: absolute;
  top: 160px;
  right: -23px;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    top: auto;
    bottom: -29px;
    right: 49%;
  }
`;
